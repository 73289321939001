import React from 'react';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import BrandGrid from '../components/BrandGrid/BrandGrid';

import Seo from '../components/Seo';

const ThanksForSigningUpPage = () => {
  return (
    <>
      <Seo
        title="Thank you for signing up · Medical Web Experts"
        canonical="/thanks-for-signing-up/"
      />
      <BrandGrid colored />
      <div className="d-flex align-items-lg-center full-height ui-layout__main--nmt">
        <Container>
          <Row className="text-center text-lg-start">
            <Col lg={6}>
              <h1 className="h1--not-decorated font-size-xl">
                Thank you for signing up!
              </h1>
              <p className="my-5 font-size-lg">
                We are looking forward to keep you posted with updates and
                interesting articles.
              </p>
              <Row className="align-items-center justify-content-center justify-content-lg-start">
                <Col className="col-auto">
                  <Button as={Link} variant="primary" to="/contact-us/">
                    Contact Us
                  </Button>
                </Col>
                <Col className="col-auto">
                  <Button
                    as={Link}
                    variant="link"
                    to="/"
                    className="text-decoration-none"
                  >
                    Homepage
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ThanksForSigningUpPage;
